import React, {
  useEffect,
  useState,
  // useState
} from "react";
import App from "../App";
import {
  Route,
  useLocation,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useOutlet,
  // useNavigate,
} from "react-router-dom";
import About from "@pages/about";
import LearningSession from "@pages/LearningSession/LearningSession";
import EditPage from "@pages/editPage";
import { Intro } from "@pages/Intro";
import LearningSessionReversed from "@pages/LearningSessionReversed";
import Slides from "@pages/slides";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Login from "@pages/Login";
import SignUp from "@pages/SignUp";
import userActions from "@store/auth/actions";
import Dashboard from "@pages/Dashboard";
import Modify, { loader as modifyLoader } from "@pages/Dashboard/Modify";
import FnT, { loader as previewLoader } from "@pages/Dashboard/Preview";
import { CookieAndPrivacy } from "@components/CookieAndPrivacy";
// import Sidebar from "@components/Sidebar";
import PublicSets from "@components/InputComponent/publicSetsComponents/PublicSets";
import MoreOptions, { moreLoader } from "../Pages/More";
import ReversedTest from "@components/InputComponent/ReversedTest";
import Collection from "@pages/Collection";
import { useClearCacheCtx } from "react-clear-cache";
import ProfilePage from "@pages/Profile";
import AddCollectionPage from "@pages/Collection/AddCollection";
import { BigCollections } from "@pages/Collection/bigCollections";
import Quiz from "@pages/quiz/quiz";
// import { useMemo } from "react";
// import PasswordProtected from "@utils/Password";
// import Logo from "@utils/Logo";
// import { Container } from "react-bootstrap";
// import MenuButton from "@components/MenuButton";

const AnimatedOutlet = () => {
  const o = useOutlet();
  // const [outlet] = useState(o);
  return o;
  // return <>{outlet}</>;
};

const AnimatedComponents = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0, y: location.pathname === "/" ? 0 : -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: location.pathname === "/" ? 0 : 20 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatedOutlet />
      </motion.div>
    </AnimatePresence>
  );
};

const ErrorComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>404</h1>
    </div>
  );
};

const nonLoggedInRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AnimatedComponents />}>
      <Route errorElement={<ErrorComponent />} index element={<Intro />} />
      <Route
        path="learning-session"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/reversed/numbers/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        path="learning-session/reversed/words/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="slides"
        element={<Slides />}
      />
      {/* <Route path="" element={<Sidebar />} errorElement={<ErrorComponent />}> */}
      <Route
        path="/reversed"
        element={<ReversedTest />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/big"
        element={<BigCollections />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections"
        element={<Collection />}
        errorElement={<ErrorComponent />}
      />
      <Route errorElement={<ErrorComponent />} path="app" element={<App />} />
      <Route
        errorElement={<ErrorComponent />}
        path="about"
        element={<About />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="public"
        element={<PublicSets />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="more"
        loader={moreLoader}
        element={<MoreOptions />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="edit-set"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path=":id"
        element={<EditPage />}
      />
      <Route
        path="/quiz"
        element={<Quiz />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="login"
        element={<Login />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="signup"
        element={<SignUp />}
        errorElement={<ErrorComponent />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="/profile"
        element={<Navigate to="/" />}
      />
      <Route
        path="dashboard/*"
        element={<Navigate to="/app" />}
        errorElement={<ErrorComponent />}
      />
      <Route path="*" element={<ErrorComponent />} />
      {/* </Route> */}
    </Route>
  )
);
const loggedInRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<AnimatedComponents />}
      errorElement={<ErrorComponent />}
    >


      <Route index errorElement={<ErrorComponent />} element={<Intro />} />
      <Route
        path="learning-session"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/reversed/numbers/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        path="learning-session/reversed/words/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="slides"
        element={<Slides />}
      />
      {/* <Route path="" errorElement={<ErrorComponent />} element={<Sidebar />}> */}
      <Route errorElement={<ErrorComponent />} path="app" element={<App />} />
      <Route
        errorElement={<ErrorComponent />}
        path="about"
        element={<About />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="more"
        loader={moreLoader}
        element={<MoreOptions />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="public"
        element={<PublicSets />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="edit-set"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path=":id"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="profile"
        element={<ProfilePage />}
      />
      <Route
        path="/reversed"
        element={<ReversedTest />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/big"
        element={<BigCollections />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections"
        element={<Collection />}
        errorElement={<ErrorComponent />}
      />

      <Route
        path="/collections/add"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />

      <Route
        path="/collections/edit/:id"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />

      <Route
        path="login"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />
      <Route
        path="signup"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />
      <Route
        path="dashboard/*"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />

      <Route path="*" element={<ErrorComponent />} />
      {/* </Route> */}
    </Route>
  )
);

const collectorInRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<AnimatedComponents />}
      errorElement={<ErrorComponent />}
    >
      <Route
        path="/quiz"
        element={<Quiz />}
        errorElement={<ErrorComponent />}
      />
      <Route index errorElement={<ErrorComponent />} element={<Intro />} />
      <Route
        path="learning-session"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/reversed/numbers/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        path="learning-session/reversed/words/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="slides"
        element={<Slides />}
      />
      {/* <Route path="" errorElement={<ErrorComponent />} element={<Sidebar />}> */}
      <Route errorElement={<ErrorComponent />} path="app" element={<App />} />
      <Route
        errorElement={<ErrorComponent />}
        path="about"
        element={<About />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="more"
        loader={moreLoader}
        element={<MoreOptions />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="public"
        element={<PublicSets />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="edit-set"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path=":id"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="profile"
        element={<ProfilePage />}
      />
      <Route
        path="/reversed"
        element={<ReversedTest />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/big"
        element={<BigCollections />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections"
        element={<Collection />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections/add"
        element={<AddCollectionPage />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections/edit/:id"
        element={<AddCollectionPage />}
        errorElement={<ErrorComponent />}
      />

      <Route
        path="login"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />
      <Route
        path="signup"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />
      <Route
        path="dashboard/*"
        errorElement={<ErrorComponent />}
        element={<Navigate to="/app" />}
      />
      <Route path="*" element={<ErrorComponent />} />
      {/* </Route> */}
    </Route>
  )
);

const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AnimatedComponents />}>
      <Route errorElement={<ErrorComponent />} index element={<Intro />} />
      <Route
        path="learning-session"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="/quiz"
        element={<Quiz />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="learning-session/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSession />}
      />
      <Route
        path="learning-session/reversed/numbers/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        path="learning-session/reversed/words/:id"
        errorElement={<ErrorComponent />}
        element={<LearningSessionReversed />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="slides"
        element={<Slides />}
      />
      {/* <Route errorElement={<ErrorComponent />} path="" element={<Sidebar />}> */}
      <Route errorElement={<ErrorComponent />} path="app" element={<App />} />
      <Route
        errorElement={<ErrorComponent />}
        path="about"
        element={<About />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="more"
        loader={moreLoader}
        element={<MoreOptions />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="public"
        element={<PublicSets />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="edit-set"
        element={<EditPage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="/profile"
        element={<ProfilePage />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path=":id"
        element={<EditPage />}
      />
      <Route
        path="/reversed"
        element={<ReversedTest />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/big"
        element={<BigCollections />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections"
        element={<Collection />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections/add"
        element={<AddCollectionPage />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="/collections/edit/:id"
        element={<AddCollectionPage />}
        errorElement={<ErrorComponent />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="login"
        element={<Navigate to="/app" />}
      />
      <Route
        errorElement={<ErrorComponent />}
        path="signup"
        element={<Navigate to="/app" />}
      />
      <Route errorElement={<ErrorComponent />} path="dashboard">
        <Route
          errorElement={<ErrorComponent />}
          index
          element={<Dashboard />}
        />
        <Route
          errorElement={<ErrorComponent />}
          path=":collection/create"
          loader={modifyLoader}
          element={<Modify />}
        />
        <Route
          errorElement={<ErrorComponent />}
          path=":collection/edit/:id"
          loader={modifyLoader}
          element={<Modify />}
        />
        <Route
          errorElement={<ErrorComponent />}
          path=":collection/preview"
          loader={previewLoader}
          element={<FnT />}
        />
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route path="*" element={<ErrorComponent />} />
      {/* </Route> */}
    </Route>
  )
);

const WebRoutes = () => {
  const { isAuth, isAdmin, isCollector } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.attachAuthListener());
  }, [dispatch]);
  const { emptyCacheStorage, isLatestVersion } = useClearCacheCtx();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [emptyCacheStorage, isLatestVersion]);
  // const isCookiesAccepted = useMemo(
  //   () => localStorage.getItem("isCookiesAccepted"),
  //   []
  // );
  return (
    <div
      style={{
        backgroundColor: `var(--bgColor)`,
        height: "100vh",
        transition: "background-color 0.3s ease",
        overflow: "hidden auto",
      }}
    >
      <AnimatePresence mode="wait">
        <RouterProvider
          router={
            isAuth
              ? isAdmin
                ? adminRouter
                : isCollector
                  ? collectorInRouter
                  : loggedInRouter
              : nonLoggedInRouter
          }
        />
      </AnimatePresence>
      <CookieAndPrivacy />
    </div>
  );
};
export default WebRoutes;
