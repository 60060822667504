import { useCallback, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Col as MDBCol,
  Container as MDBContainer,
  Row as MDBRow,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import successImg from "@assets/icons/success.gif";
import { AlertMessage } from "@components/Alert";
import BlinkAnimatedButton from "@components/BlinkAnimatedButton";
import CreateLink from "@components/EditPageCompoment/CreateLink";
import SvgButton from "@components/EditPageCompoment/SvgButton";
import { Header } from "@components/Header";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import MenuButton from "@components/MenuButton";
import { Loader } from "@components/loader";
import firebase from "@config/firebaseConfig";
import collectionActions from "@store/collections/action";
import { setId, setIsAuthor, setSentences } from "@store/sentences/action";
import { splitSentanceFun } from "@utils/splitSentance";
import "codemirror/lib/codemirror.css";
import { useEffect } from "react";
import CodeMirror from "react-codemirror";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { My_TARGET_COLLECTION } from "@store/collections/actionTypes";
import { Tooltip } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";
import { blinkAnimation } from "@helpers/index";
import { dotSVG, editSVG, folderSVG, persentationSVG, quizSVG } from "./Intro/introSVGS";
import flashCardIcon from "assets/icons/flash-card.svg";


const customStyles = {
  caret: {
    borderLeft: "1px solid var(--bgColor)",
  },
};

const firestore = firebase.firestore;

export default function EditCode() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndexNumber, setDeleteIndexNumber] = useState();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  // const [isEditingMode, setIsEditingMode] = useState(false);
  const [currentLineNo, setCurrentLineNo] = useState(null);
  const [isSubtitles, setIsSubtitles] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobileScreen(true);
      options2.lineNumbers = true;
    }
  }, []);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        setIsMobileScreen(true);
        options2.lineNumbers = true;
      } else {
        setIsMobileScreen(false);
        options2.lineNumbers = false;
      }
    });
  }, []);
  useEffect(() => {
    if (codemirror.current) {
      const doc = codemirror.current.getCodeMirror().getDoc();
      const lastLine = doc.lastLine();
      const currentLine = doc.getCursor().line;
      doc.addLineClass(currentLine, "text", "fw-bold");
    }
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        const activeElement = document.activeElement;
        const editors = [codemirror.current, codeMirrorSubtitle.current, codeMirrorLink.current].filter(Boolean);
        const currentIndex = editors.findIndex(editor => editor.getCodeMirror().getInputField() === activeElement);
        const nextIndex = (currentIndex + 1) % editors.length;
        const currentEditor = editors[currentIndex];
        const nextEditor = editors[nextIndex];

        if (nextEditor) {
          const currentCM = currentEditor.getCodeMirror();
          const nextCM = nextEditor.getCodeMirror();
          const currentDoc = currentCM.getDoc();
          const nextDoc = nextCM.getDoc();
          const currentCursor = currentDoc.getCursor();

          nextCM.focus();

          const currentLine = currentCursor.line;
          const lastLine = nextDoc.lastLine();

          if (nextIndex === 0) {
            if (currentLine < lastLine) {
              const targetCh = nextDoc.getLine(currentLine + 1).length;
              nextDoc.setCursor(currentLine + 1, targetCh); // Move to the next line if it exists
            } else {
              nextDoc.replaceRange('\n', { line: lastLine, ch: nextDoc.getLine(lastLine).length }); // Add a new line if it doesn't exist
              nextDoc.setCursor(lastLine + 1, 0); // Move to the new line
            }
          } else {
            const targetLine = Math.min(currentCursor.line, lastLine);
            const targetCh = nextDoc.getLine(targetLine).length;
            if (currentLine > lastLine) {
              nextDoc.replaceRange('\n', { line: lastLine, ch: nextDoc.getLine(lastLine).length }); // Add a new line if it doesn't exist
              nextDoc.setCursor(lastLine + 1, 0); // Move to the new line
            } else {
              nextDoc.setCursor(targetLine, targetCh);
            }
          }

          editors.forEach(editor => {
            const doc = editor.getCodeMirror().getDoc();
            const editorLastLine = doc.lastLine();
            const editorCurrentLine = doc.getCursor().line;
            doc.removeLineClass(editorCurrentLine - 1, "text", "fw-bold");
            doc.addLineClass(editorCurrentLine, "text", "fw-bold");
          });
        }
      }
      else if (e.ctrlKey && e.key === 'Delete') {
        e.preventDefault();
        const activeElement = document.activeElement;
        const editors = [codemirror.current, codeMirrorSubtitle.current, codeMirrorLink.current].filter(Boolean);
        const currentEditor = editors.find(editor => editor.getCodeMirror().getInputField() === activeElement);

        if (currentEditor) {
          const cm = currentEditor.getCodeMirror();
          const doc = cm.getDoc();
          const lastLine = doc.lastLine();
          const currentLine = doc.getCursor().line;
          if (lastLine > 0) {
            doc.replaceRange('',
              { line: lastLine - 1, ch: doc.getLine(lastLine - 1).length },
              { line: lastLine, ch: doc.getLine(lastLine).length }
            );

            doc.removeLineClass(currentLine, "text", "fw-bold");
            doc.addLineClass(currentLine - 1, "text", "fw-bold");
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteIndexNumber();
  };
  const copyTableToClipboard = () => {
    const codeLines = editPageState.code.split('\n');
    const subtitleLines = editPageState.subtitle.split('\n');
    const linkLines = editPageState.link.split('\n');
    const maxLines = Math.max(codeLines.length, subtitleLines.length);

    let clipboardText = '';
    for (let i = 0; i < maxLines; i++) {
      const codeLine = codeLines[i] ? codeLines[i].replace(/\t/g, ' ') : '';
      const subtitleLine = subtitleLines[i] ? subtitleLines[i].replace(/\t/g, ' ') : '';
      const linkLine = linkLines[i] ? linkLines[i].replace(/\t/g, ' ') : '';
      clipboardText += `${codeLine}\t${subtitleLine}\t${linkLine}\n`;

    }

    navigator.clipboard.writeText(clipboardText.trim())
      .then(() => toast.success('Table copied successfully'))
      .catch(() => toast.error('Failed to copy table'));
  };
  const pasteTableFromClipboard = () => {
    navigator.clipboard.readText()
      .then(text => {
        const lines = text.split('\n');
        const codeLines = [];
        const subtitleLines = [];
        const linkLines = [];
        for (let line of lines) {
          const [code, subtitle, links] = line.split('\t');
          codeLines.push(code);
          subtitleLines.push(subtitle);
          linkLines.push(links);
        }
        const code = codeLines.join('\n');
        const subtitle = subtitleLines.join('\n');
        const link = linkLines.join('\n');
        codemirror.current.getCodeMirror().setValue(code);
        codeMirrorSubtitle.current.getCodeMirror().setValue(subtitle);
        codeMirrorLink.current.getCodeMirror().setValue(link);
        toast.success('Table pasted successfully');
      })
      .catch(() => toast.error('Failed to paste table'));
  }
  const swapTableContent = () => {
    const currentCodeValue = codemirror.current.getCodeMirror().getValue();
    const currentSubtitleValue = codeMirrorSubtitle.current.getCodeMirror().getValue();

    // Swap the values
    codemirror.current.getCodeMirror().setValue(currentSubtitleValue);
    codeMirrorSubtitle.current.getCodeMirror().setValue(currentCodeValue);
    toast.success('Table contents swapped ');
  }

  // redux hooks
  const sentencesStore = useSelector((state) => state.sentences.sentences);
  // console.log("Sentance => " + JSON.stringify(sentencesStore));
  // router hooks
  let { id } = useParams();
  const navigate = useNavigate();
  const { uid, user, isAuth } = useSelector((state) => state.auth);
  const [isCollectionAuther, setIsCollectionAuther] = useState(false);
  const [limitExceded, setLimitExceded] = useState(false);
  const { collections } = useSelector((state) => state.collections);
  let { sets } = useSelector((state) => state.collections.targetCollection);
  const [showSentanceDetailFields, setShowSentanceDetailFields] =
    useState(false);

  // setSentences setId
  const dispatch = useDispatch();
  // ref
  const codemirror = useRef(null);
  const codeMirrorSubtitle = useRef(null);
  const codeMirrorLink = useRef(null);

  // state hooks
  const [editPageState, setEditPageState] = useState({
    code: "",
    subtitle: "",
    link: "",
    sentences: [],
    showEditor: false,
    exceed: false,
    sentencesLength: 0,
    shareUrl: "",
    URLload: false,
    hideMessage: true,
    loader: true,
    hideAll: false,
    title: "",
    username: "",
    userName: "",
    language: "English",
    socialPlatform: "facebook",
    socialLinkName: "",
    socialLink: "",
    successModal: false,
    src: successImg,
    linkType: "private",
    sentenceData: {},
    collectionName: "",
  });

  const updateCurrentLine = useCallback((cm) => {
    const cursor = cm.getCursor();
    setCurrentLineNo(cursor.line);
  }, []);

  let options = {
    lineNumbers: false,
    mode: "text/plain",
    theme: "default",
    lineWrapping: true,
    viewportMargin: Infinity,
    onCursorActivity: updateCurrentLine,
    extraKeys: {
      "Tab": false,
      "Enter": (cm) => {
        const doc = cm.getDoc();
        const cursor = doc.getCursor();
        const lastLine = doc.lastLine();
        const currentLine = doc.getCursor().line;
        const newLine = lastLine + 1;

        doc.replaceRange("\n", cursor);
        for (let i = 0; i <= lastLine; i++) {
          doc.removeLineClass(i, 'text', 'fw-bold');
          doc.removeLineClass(i, 'background', 'last-line-bg');
          doc.removeLineClass(i, 'text', 'last-line-text');
        }
        doc.addLineClass(currentLine, "text", "fw-bold");
      }
    }
  };

  let options2 = {
    lineNumbers: false,
    mode: "text/plain",
    extraKeys: {
      "Enter": (cm) => {
        const doc = cm.getDoc();
        const cursor = doc.getCursor();
        const lastLine = doc.lastLine();
        const currentLine = doc.getCursor().line;

        doc.removeLineClass(currentLine, "text", "fw-bold");
        doc.replaceRange("\n", cursor);
        doc.addLineClass(currentLine + 1, "text", "fw-bold");
      }
    }
  };
  const getCollections = useCallback(async () => {
    console.log("Get COllection Function Run");
    if (collections.length === 0 && isAuth) {
      console.log("Get COllection Function Run when collection is empty");
      dispatch(collectionActions.getCollectionsData(uid));
    }
  }, [collections.length, dispatch, isAuth, uid]);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  useEffect(() => {
    console.log("Use Effect Calling");
    console.log("sentencesStore", sentencesStore);
    if (id) {
      // console.log("id", id);
      setEditPageState((editPageState) => {
        return { ...editPageState, loader: true };
      });

      firestore()
        .collection("sets")
        .doc(id)
        .get()
        .then(async (response) => {
          const storeData = response.data();
          console.log("Get Set Data Firebase", storeData);
          setIsCollectionAuther(storeData.createdBy === uid);
          // if (storeData.createdBy === uid) {
          //   changeFullScreen(true);
          // } else {
          //   changeFullScreen(false);
          // }
          let code = "",
            subtitle = "",
            link = "",
            exceed = false;
          for (const obj of storeData?.sentences) {
            // console.log("obj", obj);
            code += obj.sentence + "\n";
            subtitle += (obj.subtitle || "") + "\n";
            link += (obj.link || "") + "\n";
            if (obj.sentence.length >= 40) {
              exceed = true;
            } else {
              exceed = false;
            }
          }

          if (subtitle.trim().length === 0) {
            // setShowSentanceDetailFields(false);
            // changeFullScreen(false);
          } else {
            // changeFullScreen(true);
            // setShowSentanceDetailFields(true);
            setIsSubtitles(true);
          }

          dispatch(
            collectionActions.getCollectionSetsData(storeData.collectionName)
          );

          setEditPageState((editPageState) => {
            return {
              ...editPageState,
              sentences: sentencesStore,
              code,
              subtitle,
              link,
              showEditor: true,
              exceed,
              loader: false,
              sentenceData: storeData,
              ...storeData,
            };
          });
          const stats = {
            opened: firestore.FieldValue.increment(1),
            lastOpened: firestore.FieldValue.serverTimestamp(),
          };
          stats.openedAt = firestore.FieldValue.arrayUnion(
            firestore.FieldValue.serverTimestamp()
          );
          if (uid) {
            stats.lastOpenedBy = uid;
            stats.openedBy = firestore.FieldValue.arrayUnion(uid);
          }
          await firestore().collection("sets").doc(response.id).update(stats);
        });
    } else if (sentencesStore.length > 0) {
      let code = "";
      let subtitle = "";
      let link = "";
      sentencesStore.forEach((el) => {
        code += el.sentence + "\n";
        subtitle += (el.subtitle || "") + "\n";
        link += (el.link || "") + "\n";
      });

      if (subtitle.trim().length === 0) {
        // setShowSentanceDetailFields(false);
        // changeFullScreen(false);
      } else {
        setIsSubtitles(true);
        // changeFullScreen(true);
        // setShowSentanceDetailFields(true);
      }

      codemirror.current?.editor?.setValue?.(code);
      codeMirrorSubtitle.current?.editor?.setValue?.(subtitle);
      codeMirrorLink.current?.editor?.setValue?.(link);

      setEditPageState((editPageState) => {
        return {
          ...editPageState,
          sentences: sentencesStore,
          code,
          subtitle,
          link,
          showEditor: true,
          loader: false,
        };
      });
    } else {
      console.log("cbscbskcbicb");
      setEditPageState((editPageState) => {
        return { ...editPageState, showEditor: true };
      });
      navigate("/");
    }
    setEditPageState((editPageState) => {
      return { ...editPageState, sentencesLength: sentencesStore.length };
    });
  }, [sentencesStore, id, navigate, uid]);

  // methods
  const splitSentences = (route = "/learning-session") => {
    let { code, link, subtitle } = editPageState;
    console.log("Split Sentace Fun");
    console.log("Code", code);
    console.log("Subtitle", subtitle);
    console.log("Link", link);
    if (code.trim().length === 0) {
      AlertMessage({ message: "Please add some text or paste it" });
      return;
    }

    let sentences = code.split("\n");
    let subtitles = subtitle.split("\n");
    let links = link.split("\n");

    sentences = sentences
      .filter((el) => el.trim())
      .map((e) => {
        console.log("sentance", e);
        return e.replace(/\s{2,}/g, " ").trim();
      });

    subtitles = subtitles
      // .filter((el) => el.trim())
      .map((e) => {
        console.log("Subtitle", e);
        return e.replace(/\s{2,}/g, " ").trim();
      });

    links = links
      // .filter((el) => el.trim())
      .map((e) => {
        console.log("Link", e);
        return e.replace(/\s{2,}/g, " ").trim();
      });

    console.log("Sentances", sentences);
    console.log("Subtitles", subtitles);
    console.log("Links", links);
    let sentencesData = [];
    for (let i = 0; i < sentences.length; i++) {
      sentencesData.push({
        sentence: sentences[i].replace(/\s{2,}/g, " ").trim(),
        subtitle: subtitles[i]
          ? subtitles[i].replace(/\s{2,}/g, " ").trim()
          : "",
        link: links[i] ? links[i].replace(/\s{2,}/g, " ").trim() : "",
        mastered: null,
        tried: false,
      });
    }
    console.log("Sentences Data", sentencesData);

    // const sentences = code.split(/[\n?!.]/).map((el) => {
    //   return {
    //     sentence: el.replace(/\s{2,}/g, " ").trim(),
    //     mastered: true,
    //     tried: false,
    //   };
    // });
    console.log("Sentences len", sentences.length);
    if (sentences.length > 1000) {
      toast.error("You can't add more than 1000 lines");
      setLimitExceded(true);
      return;
    } else {
      setLimitExceded(false);
    }

    dispatch(
      setSentences(
        sentencesData.map((e) => {
          let sentence = e.sentence;
          sentence = sentence
            .replaceAll("‘", "'")
            .replaceAll("’", "'")
            .replaceAll("‛", "'")
            .replaceAll("`", "'")
            .replaceAll("“", '"')
            .replaceAll("”", '"')
            .replaceAll("‟", '"')
            .replaceAll("„", '"');
          return { ...e, sentence };
        })
      )
    );
    // if (editPageState.collectionName) {
    //   //   dispatch(
    //   //     collectionActions.getCollectionSetsData(editPageState.collectionName)
    //   //   );
    //   dispatch({
    //     type: My_TARGET_COLLECTION,
    //     payload: editPageState.collectionName,
    //   });
    // }

    if (id) {
      dispatch(setId(id));
    }
    dispatch(setIsAuthor(isCollectionAuther));
    navigate(route);
  };

  const shareSet = async () => {
    if (!editPageState.collectionName) {
      return toast.error("Please Select Collection");
    }
    setEditPageState((editPageState) => {
      return {
        ...editPageState,
        URLload: true,
        loader: true,
      };
    });
    const newSentence = createSentancesArray();
    if (newSentence.length > 1000) {
      setEditPageState((editPageState) => {
        return {
          ...editPageState,
          URLload: false,
          loader: false,
        };
      });
      toast.error("You can't add more than 1000 lines");
      setLimitExceded(true);
      return;
    } else {
      setLimitExceded(false);
    }

    const set =
      editPageState.linkType !== "quick link"
        ? {
          sentences: newSentence,
          title: editPageState.title.toLowerCase(),
          // userName: editPageState.userName,
          language: editPageState.language,
          userName: user?.name.toLowerCase().replace(" ", "-"),
          collectionName: editPageState.collectionName,
          lines: newSentence.length,
          isPrivate: editPageState.linkType === "private",
          titleDefault: editPageState.title,
          startCount: !editPageState.shareUrl
            ? 0
            : editPageState.startCount || 0,
          profile:
            editPageState.socialLinkName &&
            `https://${editPageState.socialPlatform}.com/${editPageState.socialPlatform === "tiktok"
              ? "@"
              : editPageState.socialPlatform === "snapchat"
                ? "add/"
                : ""
            }${editPageState.socialLinkName}`,
          createdAt: firestore.FieldValue.serverTimestamp(),
        }
        : {
          sentences: newSentence,
          isPrivate: true,
          startCount: 0,
          opened: 0,
          lines: newSentence.length,
          userName: user?.name.toLowerCase().replace(" ", "-"),
          collectionName: editPageState.collectionName,
          type: "quick link",
          createdAt: firestore.FieldValue.serverTimestamp(),
        };
    if (uid) set.createdBy = uid;
    console.log("On Save Edit Page State", set);
    toast.loading("Creating Set...", { id: "shareSet" });
    await firestore()
      .collection("sets")
      .add(set)
      .then((resp) => {
        setEditPageState((editPageState) => {
          return {
            ...editPageState,
            shareUrl: "/" + resp.id,
            URLload: false,
          };
        });
        toast.success("Set Created Successfully", { id: "shareSet" });
        setEditPageState((editPageState) => {
          return { ...editPageState, loader: false, successModal: true };
        });
      });
  };

  const createSentancesArray = () => {
    const newSentence = editPageState.code
      .split("\n")
      .filter((data) => data.length !== 0)
      .map((data) => ({
        sentence: data,
      }));
    console.log("Sentance", newSentence);

    const newSentanceSubtitle = editPageState.subtitle
      .split("\n")
      // .filter((data) => data.length !== 0)
      .map((data) => ({
        subtitle: data,
      }));

    console.log("Sentance Subtitle", newSentanceSubtitle);
    const newSentanceLink = editPageState.link
      .split("\n")
      // .filter((data) => data.length !== 0)
      .map((data) => ({
        link: data,
      }));
    console.log("Sentance Link", newSentanceLink);

    const newSentanceData = [];
    for (let i = 0; i < newSentence.length; i++) {
      newSentanceData.push({
        sentence: newSentence[i].sentence,
        subtitle: newSentanceSubtitle[i]?.subtitle || "",
        link: newSentanceLink[i]?.link || "",
      });
    }
    console.log("New Sentance Data", newSentanceData);
    return newSentanceData;
  };

  const updateSet = async () => {
    setEditPageState((editPageState) => {
      return {
        ...editPageState,
        URLload: true,
        loader: true,
      };
    });

    const newSentence = createSentancesArray();
    if (newSentence.length > 1000) {
      setEditPageState((editPageState) => {
        return {
          ...editPageState,
          URLload: false,
          loader: false,
        };
      });
      setLimitExceded(true);
      toast.error("You can't add more than 1000 lines");
      return;
    } else {
      setLimitExceded(false);
    }
    console.log("Edit State", editPageState);
    // // if (!editPageState.shareUrl) {
    const set =
      editPageState.linkType !== "quick link"
        ? {
          sentences: newSentence,
          title: editPageState.title.toLowerCase(),
          // userName: editPageState.userName,
          language: editPageState.language,
          userName: user?.name.toLowerCase().replace(" ", "-"),
          collectionName: editPageState.collectionName,
          lines: newSentence.length,
          isPrivate: editPageState.linkType === "private",
          titleDefault: editPageState.title,
          profile:
            editPageState.socialLinkName &&
            `https://${editPageState.socialPlatform}.com/${editPageState.socialPlatform === "tiktok"
              ? "@"
              : editPageState.socialPlatform === "snapchat"
                ? "add/"
                : ""
            }${editPageState.socialLinkName}`,
        }
        : {
          sentences: newSentence,
          isPrivate: true,
          lines: newSentence.length,
          userName: user?.name.toLowerCase().replace(" ", "-"),
          collectionName: editPageState.collectionName,
          type: "quick link",
          // createdAt: firestore.FieldValue.serverTimestamp(),
        };
    // if (uid) set.createdBy = uid;
    console.log("Update Set editPageState", editPageState);
    console.log("On Update Page State set", set);
    console.log("On Update Page State id", id);

    if (id) {
      const toaster = toast.loading("Updating Set");
      await firestore()
        .collection("sets")
        .doc(id)
        .update({ ...set })
        .then(() => {
          setEditPageState((editPageState) => {
            return {
              ...editPageState,
              shareUrl: "/" + id,
              URLload: false,
            };
          });
          setEditPageState((editPageState) => {
            return { ...editPageState, loader: false, successModal: true };
          });
          toast.success("Set Updated Successfully", { id: toaster });
        });
    } else {
      console.log("Update Set editPageState", editPageState);
      const newId = editPageState.shareUrl.split("/")[1];
      const toaster = toast.loading("Creating Set...");
      await firestore()
        .collection("sets")
        .doc(newId)
        .update({ ...set })
        .then(() => {
          setEditPageState((editPageState) => {
            return {
              ...editPageState,
              shareUrl: "/" + newId,
              URLload: false,
            };
          });
          setEditPageState((editPageState) => {
            return { ...editPageState, loader: false, successModal: true };
          });
          toast.success("Set Created Successfully", { id: toaster });
        });
    }
  };

  const destructreSentanceArray = (mySentance) => {
    let code = mySentance.map((el) => el.sentence).join("\n");
    let subtitle = mySentance.map((el) => el.subtitle).join("\n");
    let link = mySentance.map((el) => el.link).join("\n");
    return { code, subtitle, link };
  };

  const handleDeleteLink = async (index, hideToast, isFromShortcut) => {
    if (!isFromShortcut) {
      document.querySelector(`#dropdown-delete-${index}`).click();
    }
    console.log("index", index);
    console.log("editPageState.sentences", editPageState.sentences);
    const mySentance = createSentancesArray();
    mySentance.splice(index, 1);
    console.log("mySentance", mySentance);
    let { code, subtitle, link } = destructreSentanceArray(mySentance);
    codemirror.current.codeMirror.setValue(code);
    codeMirrorSubtitle.current?.codeMirror.setValue(subtitle);
    codeMirrorLink.current?.codeMirror.setValue(link);
    [codemirror.current, codeMirrorSubtitle.current, codeMirrorLink.current].forEach(editor => {
      if (editor && editor.codeMirror) {
        const doc = editor.codeMirror.getDoc();
        doc.setCursor({ line: -1, ch: 0 }); // Set cursor to an invalid position
        removeAllLineClasses(doc);
      }
    });

    editPageState.sentences.splice(index, 1);
    setEditPageState((editPageState) => {
      return {
        ...editPageState,
        code: code,
        subtitle: subtitle,
        link: link,
      };
    });
    if (
      editPageState.userName === user?.name.toLowerCase().replace(" ", "-") &&
      id
    ) {
      const toaster = !hideToast ? toast.loading("Deleting Sentence") : "";
      await firestore()
        .collection("sets")
        .doc(id)
        .update({ sentences: mySentance, lines: mySentance.length })
        .then(() => {
          if (!hideToast) {
            toast.success("Sentence Deleted Successfully", { id: toaster });
          }
        });
    }

    handleCloseDeleteModal();
    setCurrentLineNo(null);
  };

  const handleMoveSet = async (sentIndex, setId) => {
    const toaster = toast.loading("Moving Set");
    const mySentance = createSentancesArray();

    await firestore()
      .collection("sets")
      .doc(setId)
      .update({
        sentences: firebase.firestore.FieldValue.arrayUnion(
          mySentance[sentIndex]
        ),
        lines: firebase.firestore.FieldValue.increment(1),
      })
      .then(() => {
        toast.success("Set Moved Successfully", { id: toaster });
      });

    handleDeleteLink(sentIndex, true, false);
  };

  const changeFullScreen = (fullScreen) => {
    // Toggle the fullscreen state
    setIsFullScreen(!isFullScreen);
    // setShowSentanceDetailFields(fullScreen);

    // Enter or exit fullscreen mode based on the current state
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const toogleEditingMode = () => {
    setShowSentanceDetailFields(!showSentanceDetailFields);
  };

  const { URLload } = editPageState;

  // if press ctl + Q open full screen
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "q") {
        changeFullScreen(!showSentanceDetailFields);
      }
      if (e.altKey && e.key === 'Delete') {
        e.preventDefault();
        if (currentLineNo !== null) {
          handleDeleteLink(currentLineNo, false, true);
        } else {
          toast.error("Please select the line to delete");
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showSentanceDetailFields, currentLineNo, handleDeleteLink, changeFullScreen]);

  const removeAllLineClasses = (doc) => {
    const lastLine = doc.lastLine();
    for (let i = 0; i <= lastLine; i++) {
      doc.removeLineClass(i, "text", "fw-bold");
      doc.removeLineClass(i, 'background', 'last-line-bg');
      doc.removeLineClass(i, 'text', 'last-line-text');
    }
  };

  const handleCursorActivity = (editor) => {
    const doc = editor.getDoc();
    const cursorPos = doc.getCursor();
    const currentLine = cursorPos.line;

    [codemirror.current, codeMirrorSubtitle.current, codeMirrorLink.current].forEach(cmInstance => {
      if (cmInstance && cmInstance.codeMirror) {
        const cmDoc = cmInstance.codeMirror.getDoc();
        removeAllLineClasses(cmDoc);

        if (editor.hasFocus() && cursorPos.line !== -1 && cursorPos.ch !== -1) {
          setCurrentLineNo(currentLine);
          cmDoc.addLineClass(currentLine, "text", "fw-bold");
          cmDoc.addLineClass(currentLine, 'background', 'last-line-bg');
          cmDoc.addLineClass(currentLine, 'text', 'last-line-text');
        } else {
          setCurrentLineNo(null)
        }

      }
    });

  };
  const handleLearnButton = async () => {
    if (id) {
      const stats = {
        startCount: firestore.FieldValue.increment(1),
        lastStart: firestore.FieldValue.serverTimestamp(),
        // lastStartedBy: uid,
        // startedBy: firestore.FieldValue.arrayUnion(uid),
        startedAt: firestore.FieldValue.arrayUnion(
          firestore.FieldValue.serverTimestamp()
        ),
      };
      if (uid) {
        stats.startedBy = firestore.FieldValue.arrayUnion(uid);
        stats.lastStartedBy = uid;
      }

      splitSentences();
      firestore().collection("sets").doc(id).update(stats);
    } else {
      splitSentences();
    }
  };
  return (
    <section>
      {editPageState.loader ? (
        <Loader isStatic />
      ) : (
        <HeaderFooterLayout hideNavbar={showSentanceDetailFields}>
          {/* {!showSentanceDetailFields && <NavbarHeader />} */}
          <div className="pt-4 position-relative">
            {/* {!showSentanceDetailFields && (
              <>{id && !isCollectionAuther && <MenuButton />}</>
            )} */}
            <ReactTooltip place="bottom" type="dark" effect="solid" />
            <MDBContainer
              className="px-3 pb-3"
              fluid={showSentanceDetailFields}
            >
              <MDBRow className="justify-content-center">
                <MDBCol
                  md="12"
                  lg={showSentanceDetailFields ? "12" : "9"}
                  xl={showSentanceDetailFields ? "12" : "7"}
                >
                  {!showSentanceDetailFields && (
                    <div className="mx-auto col-md-9">
                      <Header
                        link="/app"
                        showUserInfo={!(id && !isCollectionAuther)}
                        videoModal
                        title={
                          // id && !isCollectionAuther
                          //   ? editPageState.title
                          //   : editPageState.shareUrl || isCollectionAuther
                          //   ? "Edit Your Set"
                          //   : "Finish Your Set"
                          <>
                            {
                              <span className="fw-normal">
                                {
                                  editPageState.code
                                    .split("\n")
                                    .filter((e) => e.length > 0).length
                                }
                              </span>
                            }{" "}
                            <sapn className="text-uppercase">
                              {editPageState.title
                                ? editPageState.title
                                : "title"}
                            </sapn>
                          </>
                        }
                        tagline={
                          id && !isCollectionAuther
                            ? [
                              <>
                                <p className="mb-5">
                                  Created by{" "}
                                  <span className="text-uppercase">
                                    {editPageState?.userName || "User Name"}
                                  </span>
                                </p>
                                {/* <p>watch instructions</p> */}
                              </>,
                            ]
                            : ""
                        }
                      />
                      {!(id && !isCollectionAuther) && (
                        <div className="text-center">
                          <CreateLink
                            editPageState={editPageState}
                            id={id}
                            setEditPageState={setEditPageState}
                            URLload={URLload}
                            shareSet={shareSet}
                            isCollectionAuther={isCollectionAuther}
                            handleUpdate={updateSet}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <section className="flex-fill">
                    <div className="position-relative">
                      {editPageState.showEditor && (
                        <div className="position-relative">
                          {limitExceded && (
                            <p
                              className="fw-semibold text-body m-0 position-absolute end-0"
                              style={{
                                top: "-2rem",
                              }}
                            >
                              limit 1000 lines
                            </p>
                          )}
                          <Row>
                            <Col
                              md={
                                showSentanceDetailFields
                                  ? ["collector", "admin"].includes(user?.role)
                                    ? 5
                                    : 6
                                  : 5
                              }
                            >
                              Column A
                            </Col>
                            {showSentanceDetailFields && (
                              <>
                                <Col
                                  md={
                                    showSentanceDetailFields
                                      ? ["collector", "admin"].includes(
                                        user?.role
                                      )
                                        ? 5
                                        : 6
                                      : 5
                                  }
                                >
                                  Column B
                                </Col>
                                {["collector", "admin"].includes(
                                  user?.role
                                ) && <Col md={2}>Column C</Col>}
                              </>
                            )}
                          </Row>
                          <div
                            className={`border border-dark ${showSentanceDetailFields
                              ? "row g-0 align-items-stretch"
                              : ""
                              } ${!isMobileScreen ? "overflow-auto" : ""}`}
                            style={
                              !isMobileScreen
                                ? {
                                  height: "calc(100vh - 120px)",
                                  maxHeight: showSentanceDetailFields
                                    ? "calc(100vh - 120px)"
                                    : "300px",
                                }
                                : {}
                            }
                          >
                            <div
                              className={
                                showSentanceDetailFields
                                  ? ["collector", "admin"].includes(user?.role)
                                    ? "col-md-5"
                                    : "col-md-6"
                                  : ""
                              }
                            >
                              <div
                                className="h-100 d-flex align-items-stretch"
                                style={
                                  isMobileScreen
                                    ? {
                                      maxHeight: "300px",
                                      overflowX: "hidden",
                                      overflowY: "auto",
                                    }
                                    : {}
                                }
                              >
                                <div
                                  className="w-50px h-100"
                                  style={{
                                    display: "inline-block",
                                    width: "50px",
                                    paddingTop: "4px",
                                  }}
                                >
                                  {editPageState.sentences.map((e, i) => (
                                    <Tooltip
                                      key={i}
                                      placement={
                                        showSentanceDetailFields
                                          ? "top"
                                          : "left-start"
                                      }
                                      arrow
                                      componentsProps={{
                                        tooltip: {
                                          style: {
                                            color: `var(--bgColor)`,
                                            background: `var(--text-color)`,
                                          },
                                        },
                                      }}
                                      title={
                                        e.sentence ||
                                        e.subtitle ||
                                        e.link ||
                                        "Empty"
                                      }
                                    >
                                      <DropdownButton
                                        id={`dropdown-delete-${i}`}
                                        autoClose="outside"
                                        key={i}
                                        drop={"end"}
                                        variant="secondary"
                                        bsPrefix={`w-100 text-center m-0 p-0 border-0 outline-0 delete-dropdown ${i === currentLineNo ? "last-line-bg last-line-text" : i % 2 === 0 ? "bg-transparent" : "bgColor-black-20"
                                          }`}
                                        title={i + 1}
                                      >
                                        <Dropdown.Item
                                          onClick={() => handleDeleteLink(i, false, false)}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                        <Dropdown.Item className="p-0">
                                          <DropdownButton
                                            key={i}
                                            drop={"end"}
                                            variant="secondary"
                                            bsPrefix={`w-100 text-center m-0 py-1 border-0 outline-0 delete-dropdown bg-dark text-bodyColor`}
                                            title={"Move"}
                                          >
                                            {sets
                                              .filter((data) => data.id !== id)
                                              .map((data, ind) => (
                                                <Dropdown.Item
                                                  className={
                                                    ind < sets.length - 2 &&
                                                    "border-bottom border-dark"
                                                  }
                                                  onClick={() =>
                                                    handleMoveSet(i, data.id)
                                                  }
                                                >
                                                  {data.title}
                                                </Dropdown.Item>
                                              ))}
                                          </DropdownButton>
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    </Tooltip>
                                  ))}
                                </div>
                                <div
                                  className="h-100"
                                  style={{
                                    display: "inline-block",
                                    width: "calc(100% - 50px)",
                                  }}
                                >
                                  <style>
                                    {`
                                   .CodeMirror-cursor {
                                    border-left: ${customStyles.caret.borderLeft.split(" ")[2]};
                                    border-width: ${customStyles.caret.borderLeft.split(" ")[0]};
                                    border-style: ${customStyles.caret.borderLeft.split(" ")[1]};
                                            }
                                       `}
                                  </style>
                                  <CodeMirror
                                    className={`editor left-border-only border-right-only rounded-0 h-100`}
                                    value={editPageState.code ?? ""}
                                    onChange={(code, i) => {
                                      setEditPageState((editPageState) => ({
                                        ...editPageState,
                                        code,
                                        sentences: code.split("\n").map((sentence) => ({
                                          sentence,
                                        })),
                                      }));

                                      console.log("Code", editPageState.sentences.length);

                                      if (editPageState.sentences.length > 1000) {
                                        setLimitExceded(true);
                                      } else {
                                        setLimitExceded(false);
                                      }
                                    }}
                                    onCursorActivity={(editor) => handleCursorActivity(editor)}
                                    ref={codemirror}
                                    options={{
                                      ...options,
                                      lineWrapping: false,
                                    }}
                                  />

                                </div>
                              </div>
                            </div>
                            {showSentanceDetailFields && (
                              <>
                                <div
                                  className={
                                    showSentanceDetailFields
                                      ? ["collector", "admin"].includes(
                                        user?.role
                                      )
                                        ? "col-md-5"
                                        : "col-md-6"
                                      : ""
                                  }
                                >
                                  <CodeMirror
                                    className="editor left-border-only rounded-0 h-100"
                                    value={editPageState.subtitle ?? ""}
                                    onChange={(code) => {
                                      setEditPageState((editPageState) => ({
                                        ...editPageState,
                                        subtitle: code,
                                      }));

                                      if (editPageState.sentences.length > 1000) {
                                        setLimitExceded(true);
                                      } else {
                                        setLimitExceded(false);
                                      }
                                    }}
                                    onCursorActivity={(editor) => handleCursorActivity(editor)}
                                    ref={codeMirrorSubtitle}
                                    options={options2}
                                  />
                                </div>
                                {["collector", "admin"].includes(
                                  user?.role
                                ) && (
                                    <div className="col-md-2">
                                      <CodeMirror
                                        className="editor left-border-only rounded-0 h-100"
                                        value={editPageState.link ?? ""}
                                        onChange={(code) => {
                                          setEditPageState((editPageState) => ({
                                            ...editPageState,
                                            link: code,
                                          }));

                                          if (editPageState.sentences.length > 1000) {
                                            setLimitExceded(true);
                                          } else {
                                            setLimitExceded(false);
                                          }
                                        }}
                                        onCursorActivity={(editor) => handleCursorActivity(editor)}
                                        ref={codeMirrorLink}
                                        options={options2}
                                      />
                                    </div>


                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* )} */}
                    {showSentanceDetailFields && (
                      <div className="btn-toolbar" role="toolbar">
                        <div className="row g-4 mt-3 position-relative w-100">
                          <Col xs={5} lg={2} className="order-0">
                            <div className="d-inline-flex gap-3">
                              {/* Close Editing Mode */}
                              <SvgButton
                                tip="close editing mode"
                                border={true}
                                smallSize={true}
                                onPress={toogleEditingMode}
                                disabled={false}
                                editPageState={editPageState}
                              />
                              {/* Full Screen */}
                              <SvgButton
                                tip={
                                  isFullScreen
                                    ? "exit full screen"
                                    : "full screen"
                                }
                                border={true}
                                smallSize={true}
                                disabled={false}
                                onPress={() => {
                                  // setShowSentanceDetailFields(
                                  //   !showSentanceDetailFields
                                  // );
                                  changeFullScreen(!showSentanceDetailFields);
                                }}
                                editPageState={editPageState}
                              />

                              <SvgButton
                                tip={"save the changes"}
                                border={true}
                                smallSize={true}
                                disabled={
                                  (editPageState.title === "" ||
                                    editPageState.collectionName === "" ||
                                    !isAuth) &&
                                  (!editPageState.shareUrl ||
                                    isCollectionAuther)
                                }
                                opacity={
                                  (editPageState.title === "" ||
                                    editPageState.collectionName === "" ||
                                    !isAuth) &&
                                    (!editPageState.shareUrl ||
                                      isCollectionAuther)
                                    ? 0.3
                                    : 1
                                }
                                onPress={updateSet}
                                editPageState={editPageState}
                              />
                            </div>
                          </Col>
                          <Col xs={13} lg={7} className="order-2 order-lg-1">
                            <div className="d-flex gap-2 flex-wrap justify-content-center">
                              {/* Remove Dublicates */}
                              <SvgButton
                                tip="remove duplicates"
                                onPress={() => {
                                  //  remove duplicates from all sentences and subtitle and link
                                  const mySentance = createSentancesArray();
                                  const uniqueSentences = mySentance.filter(
                                    (e, i, a) =>
                                      a.findIndex(
                                        (t) => t.sentence === e.sentence
                                        //  &&
                                        // t.subtitle === e.subtitle &&
                                        // t.link === e.link
                                      ) === i
                                  );
                                  console.log(
                                    "uniqueSentences",
                                    uniqueSentences
                                  );
                                  let { code, subtitle, link } =
                                    destructreSentanceArray(uniqueSentences);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  const noOfLinesRemoved = mySentance.length - uniqueSentences.length;
                                  toast.success(`${noOfLinesRemoved} duplicate lines removed`);
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                }}
                                editPageState={editPageState}
                              />
                              {/* Clean Extra Space */}
                              <SvgButton
                                tip="clean extra spaces"
                                onPress={() => {
                                  const mySentence = createSentancesArray();
                                  let originalLength = 0;
                                  let cleanedLength = 0;

                                  mySentence.forEach((e) => {
                                    originalLength += e.sentence.length + e.subtitle.length + e.link.length;

                                    e.sentence = e.sentence.replace(/\s{2,}/g, " ").trim();
                                    e.subtitle = e.subtitle.replace(/\s{2,}/g, " ").trim();
                                    e.link = e.link.replace(/\s{2,}/g, " ").trim();

                                    cleanedLength += e.sentence.length + e.subtitle.length + e.link.length;
                                  });

                                  let { code, subtitle, link } = destructreSentanceArray(mySentence);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(subtitle);
                                  codeMirrorLink.current?.codeMirror.setValue(link);
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });

                                  const spacesCleaned = originalLength - cleanedLength;

                                  toast.success(`${spacesCleaned} extra spaces cleaned`);

                                }}
                                editPageState={editPageState}
                              />
                              {/* Add a dot */}
                              <SvgButton
                                tip="add a dot"
                                onPress={() => {
                                  const mySentence = createSentancesArray();
                                  let dotsAdded = 0;

                                  mySentence.forEach((e) => {
                                    if (![".", "!", "?"].includes(e.sentence.slice(-1))) {
                                      e.sentence = e.sentence + ".";
                                      dotsAdded++;
                                    }
                                    if (![".", "!", "?"].includes(e.subtitle.slice(-1))) {
                                      e.subtitle = e.subtitle + ".";
                                      dotsAdded++;
                                    }
                                  });

                                  let { code, subtitle, link } = destructreSentanceArray(mySentence);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(subtitle);
                                  codeMirrorLink.current?.codeMirror.setValue(link);
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                  toast.success(`${dotsAdded} dot's added`);

                                }}
                                editPageState={editPageState}
                              />
                              {/* organize by length */}
                              <SvgButton
                                tip="organize by length"
                                onPress={() => {
                                  const mySentance = createSentancesArray();
                                  mySentance.sort(
                                    (a, b) =>
                                      a.sentence.length - b.sentence.length
                                  );
                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("Sentences organized by length");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                  // const value = organizeByLength(
                                  //   editPageState.code
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codemirror.current.codeMirror.setValue(value);

                                  // const linkValue = organizeByLength(
                                  //   editPageState.link
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorLink.current.codeMirror.setValue(
                                  //   linkValue
                                  // );

                                  // const subtitleValue = organizeByLength(
                                  //   editPageState.subtitle
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorSubtitle.current.codeMirror.setValue(
                                  //   subtitleValue
                                  // );

                                  // setEditPageState((editPageState) => {
                                  //   return {
                                  //     ...editPageState,
                                  //     code: value,
                                  //     subtitle: subtitleValue,
                                  //     link: linkValue,
                                  //   };
                                  // });
                                }}
                                editPageState={editPageState}
                              />
                              {/* organize alphabetically */}
                              <SvgButton
                                tip="organize alphabetically"
                                onPress={() => {
                                  const mySentance = createSentancesArray();
                                  mySentance.sort((a, b) =>
                                    a.sentence.localeCompare(b.sentence)
                                  );

                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("Sentences organized alphabetically");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                  // const value = organizeAlphabetically(
                                  //   editPageState.code
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codemirror.current.codeMirror.setValue(value);
                                  // const subtitleValue = organizeAlphabetically(
                                  //   editPageState.subtitle
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorSubtitle.current.codeMirror.setValue(
                                  //   subtitleValue
                                  // );
                                  // const linkValue = organizeAlphabetically(
                                  //   editPageState.link
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorLink.current.codeMirror.setValue(
                                  //   linkValue
                                  // );
                                  // setEditPageState((editPageState) => {
                                  //   return {
                                  //     ...editPageState,
                                  //     code: value,
                                  //     subtitle: subtitleValue,
                                  //     link: linkValue,
                                  //   };
                                  // });
                                }}
                                editPageState={editPageState}
                              />
                              {/* SVG Button For all to lowercase */}
                              <SvgButton
                                tip="all to lowercase"
                                onPress={() => {
                                  const mySentance = createSentancesArray();
                                  mySentance.forEach((e) => {
                                    e.sentence = e.sentence.toLowerCase();
                                    e.subtitle = e.subtitle.toLowerCase();
                                    e.link = e.link.toLowerCase();
                                  });

                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("Convert sentences to lowercase");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });

                                  // const value = editPageState.code.toLowerCase();
                                  // codemirror.current.codeMirror.setValue(value);

                                  // const subtitleValue =
                                  //   editPageState.subtitle.toLowerCase();
                                  // codeMirrorSubtitle.current.codeMirror.setValue(
                                  //   subtitleValue
                                  // );

                                  // const linkValue =
                                  //   editPageState.link.toLowerCase();
                                  // codeMirrorLink.current.codeMirror.setValue(
                                  //   linkValue
                                  // );

                                  // setEditPageState((editPageState) => {
                                  //   return {
                                  //     ...editPageState,
                                  //     code: value,
                                  //     subtitle: subtitleValue,
                                  //     link: linkValue,
                                  //   };
                                  // });
                                }}
                                editPageState={editPageState}
                              />
                              {/* SVG Button For all to capitalize */}
                              <SvgButton
                                tip="capitalize the first letter"
                                onPress={() => {
                                  const mySentance = createSentancesArray();
                                  mySentance.forEach((e) => {
                                    e.sentence =
                                      e.sentence[0].toUpperCase() +
                                      e.sentence.slice(1);
                                    e.subtitle = e.subtitle
                                      ? e.subtitle[0].toUpperCase() +
                                      e.subtitle.slice(1)
                                      : "";
                                    e.link = e.link
                                      ? e.link[0].toUpperCase() +
                                      e.link.slice(1)
                                      : "";
                                  });

                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("First letter capitalized");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                  // const value = editPageState.code
                                  //   .split("\n")
                                  //   .filter((e) => e.length > 0)
                                  //   .map((e) => e[0].toUpperCase() + e.slice(1))
                                  //   .join("\n");
                                  // codemirror.current.codeMirror.setValue(value);

                                  // const subtitleValue = editPageState.subtitle
                                  //   .split("\n")
                                  //   // .filter((e) => e.length > 0)
                                  //   .map((e) =>
                                  //     e ? e[0].toUpperCase() + e.slice(1) : ""
                                  //   )
                                  //   .join("\n");
                                  // codeMirrorSubtitle.current.codeMirror.setValue(
                                  //   subtitleValue
                                  // );

                                  // const linkValue = editPageState.link
                                  //   .split("\n")
                                  //   // .filter((e) => e.length > 0)
                                  //   .map((e) =>
                                  //     e ? e[0].toUpperCase() + e.slice(1) : ""
                                  //   )
                                  //   .join("\n");
                                  // codeMirrorLink.current.codeMirror.setValue(
                                  //   linkValue
                                  // );

                                  // setEditPageState((editPageState) => {
                                  //   return {
                                  //     ...editPageState,
                                  //     code: value,
                                  //     subtitle: subtitleValue,
                                  //     link: linkValue,
                                  //   };
                                  // });
                                }}
                                editPageState={editPageState}
                              />
                              {/*remove interpunction*/}
                              <SvgButton
                                tip="remove interpunction"
                                onPress={() => {
                                  const mySentance = createSentancesArray();
                                  let interpunctionsRemoved = 0;

                                  mySentance.forEach((e) => {

                                    const initialSentenceLength = e.sentence.length;
                                    e.sentence = e.sentence.replace(
                                      /[&/\\#@;`=^_!,+|*()$~%.":*?<>{}[\]]/g,
                                      ""
                                    );
                                    interpunctionsRemoved += initialSentenceLength - e.sentence.length;


                                    if (e.subtitle) {
                                      const initialSubtitleLength = e.subtitle.length;
                                      e.subtitle = e.subtitle.replace(
                                        /[&/\\#@;`=^_!,+|*()$~%.":*?<>{}[\]]/g, ""
                                      );
                                      interpunctionsRemoved += initialSubtitleLength - e.subtitle.length;
                                    }


                                    if (e.link) {
                                      const initialLinkLength = e.link.length;
                                      e.link = e.link.replace(
                                        /[&/\\#@;`=^_!,+|*()$~%.":*?<>{}[\]]/g, ""
                                      );
                                      interpunctionsRemoved += initialLinkLength - e.link.length;
                                    }
                                  });

                                  let { code, subtitle, link } = destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(subtitle);
                                  codeMirrorLink.current?.codeMirror.setValue(link);
                                  toast.success(`${interpunctionsRemoved} interpunctions removed`);
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                }}
                                editPageState={editPageState}
                              />
                              {/* shuffle lines */}
                              <SvgButton
                                tip="shuffle lines"
                                onPress={() => {
                                  let mySentance = createSentancesArray();
                                  mySentance.sort(() => Math.random() - 0.5);
                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("Sentences shuffled");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                  // const value = shuffle(
                                  //   editPageState.code
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codemirror.current.codeMirror.setValue(value);

                                  // const subtitleValue = shuffle(
                                  //   editPageState.subtitle
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorSubtitle.current.codeMirror.setValue(
                                  //   subtitleValue
                                  // );

                                  // const linkValue = shuffle(
                                  //   editPageState.link
                                  //     .split("\n")
                                  //     .filter((e) => e.length > 0)
                                  // ).join("\n");
                                  // codeMirrorLink.current.codeMirror.setValue(
                                  //   linkValue
                                  // );

                                  // setEditPageState((editPageState) => {
                                  //   return {
                                  //     ...editPageState,
                                  //     code: value,
                                  //     subtitle: subtitleValue,
                                  //     link: linkValue,
                                  //   };
                                  // });
                                }}
                                editPageState={editPageState}
                              />

                              {/* swap lines  */}
                              <SvgButton
                                tip="swap columns"
                                onPress={swapTableContent}

                                editPageState={editPageState}
                              />
                              {/* remove every second line break */}
                              <SvgButton
                                tip="remove every second line break"
                                onPress={() => {
                                  let lineBreaksRemoved = 0; // Initialize counter for removed line breaks

                                  // Process code
                                  const codeLines = editPageState.code.split("\n");
                                  const value = codeLines
                                    .filter((e, i) => {
                                      if (i % 2 !== 0) lineBreaksRemoved++; // Count removed line breaks
                                      return e.length > 0;
                                    })
                                    .reduce((acc, cur, i) => {
                                      if (i % 2 === 0) {
                                        acc.push(cur);
                                      } else {
                                        acc[acc.length - 1] += ` ${cur}`;
                                      }
                                      return acc;
                                    }, [])
                                    .join("\n");
                                  codemirror.current.codeMirror.setValue(value);


                                  const subtitleLines = editPageState.subtitle.split("\n");
                                  const subtitlevalue = subtitleLines
                                    .reduce((acc, cur, i) => {
                                      if (i % 2 === 0) {
                                        acc.push(cur);
                                      } else {
                                        acc[acc.length - 1] += ` ${cur}`;

                                      }
                                      return acc;
                                    }, [])
                                    .join("\n");
                                  codeMirrorSubtitle.current?.codeMirror.setValue(subtitlevalue);


                                  const linkLines = editPageState.link.split("\n");
                                  const linkvalue = linkLines
                                    .reduce((acc, cur, i) => {
                                      if (i % 2 === 0) {
                                        acc.push(cur);
                                      } else {
                                        acc[acc.length - 1] += ` ${cur}`;

                                      }
                                      return acc;
                                    }, [])
                                    .join("\n");
                                  codeMirrorLink.current?.codeMirror.setValue(linkvalue);

                                  // Update state and show toast
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: value,
                                      subtitle: subtitlevalue,
                                      link: linkvalue,
                                    };
                                  });

                                  toast.success(`${lineBreaksRemoved} line breaks removed`);
                                }}
                                editPageState={editPageState}
                              />
                              {/* remove the first half */}
                              <SvgButton
                                tip="remove the first half"
                                onPress={() => {
                                  const value = editPageState.code
                                    .split("\n")
                                    .slice(
                                      Math.floor(
                                        editPageState.code.split("\n").length /
                                        2
                                      )
                                    )
                                    .join("\n");
                                  codemirror.current.codeMirror.setValue(value);

                                  const subtitleValue = editPageState.subtitle
                                    .split("\n")
                                    .slice(
                                      Math.floor(
                                        editPageState.subtitle.split("\n")
                                          .length / 2
                                      )
                                    )
                                    .join("\n");
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitleValue
                                  );

                                  const linkValue = editPageState.link
                                    .split("\n")
                                    .slice(
                                      Math.floor(
                                        editPageState.link.split("\n").length /
                                        2
                                      )
                                    )
                                    .join("\n");
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    linkValue
                                  );
                                  toast.success("First half removed");

                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: value,
                                      subtitle: subtitleValue,
                                      link: linkValue,
                                    };
                                  });
                                }}
                                editPageState={editPageState}
                              />
                              {/* punctuation marks */}
                              <SvgButton
                                tip="punctuation marks"
                                onPress={() => {

                                  const countPunctuation = (text) => (text.match(/[.!?]/g) || []).length;

                                  const initialCodePunctuation = countPunctuation(editPageState.code);
                                  const initialSubtitlePunctuation = countPunctuation(editPageState.subtitle);
                                  const initialLinkPunctuation = countPunctuation(editPageState.link);

                                  const value = editPageState.code
                                    .split("\n")
                                    .filter((e) => e.length > 0)
                                    .map((e) => e.replace(/([.!?])/g, "$1\n"))
                                    .join("\n");

                                  const subtitleValue = editPageState.subtitle
                                    .split("\n")
                                    .filter((e) => e.length > 0)
                                    .map((e) => e.replace(/([.!?])/g, "$1\n"))
                                    .join("\n");

                                  const linkValue = editPageState.link
                                    .split("\n")
                                    .filter((e) => e.length > 0)
                                    .map((e) => e.replace(/([.!?])/g, "$1\n"))
                                    .join("\n");

                                  const finalCodePunctuation = countPunctuation(value);
                                  const finalSubtitlePunctuation = countPunctuation(subtitleValue);
                                  const finalLinkPunctuation = countPunctuation(linkValue);

                                  const totalPunctuationAdded = (finalCodePunctuation - initialCodePunctuation) +
                                    (finalSubtitlePunctuation - initialSubtitlePunctuation) +
                                    (finalLinkPunctuation - initialLinkPunctuation);

                                  codemirror.current.codeMirror.setValue(value);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(subtitleValue);
                                  codeMirrorLink.current?.codeMirror.setValue(linkValue);

                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: value,
                                      subtitle: subtitleValue,
                                      link: linkValue,
                                    };
                                  });

                                  toast.success(`${totalPunctuationAdded} punctuation marks added`);
                                }}
                                editPageState={editPageState}
                              />
                              {/* move all even lines from column A to column B */}
                              <SvgButton
                                tip="move all even lines from column A to column B"
                                onPress={() => {

                                  const mySentance = createSentancesArray();
                                  const myNewSentance = [];
                                  const myNewSubtitle = [];
                                  mySentance.forEach((e, i) => {
                                    if (i % 2 === 1) {
                                      myNewSubtitle.push(e.sentence);
                                      mySentance.slice(i, 1);
                                    } else {
                                      myNewSentance.push(e.sentence);
                                    }
                                  });
                                  mySentance.forEach((e, i) => {
                                    e.sentence = myNewSentance[i];
                                    e.subtitle = myNewSubtitle[i];
                                  });

                                  let { code, subtitle, link } =
                                    destructreSentanceArray(mySentance);
                                  codemirror.current.codeMirror.setValue(code);
                                  codeMirrorSubtitle.current?.codeMirror.setValue(
                                    subtitle
                                  );
                                  codeMirrorLink.current?.codeMirror.setValue(
                                    link
                                  );
                                  toast.success("Even lines moved to subtitle");
                                  setEditPageState((editPageState) => {
                                    return {
                                      ...editPageState,
                                      code: code,
                                      subtitle: subtitle,
                                      link: link,
                                    };
                                  });
                                }}
                                editPageState={editPageState}
                              />
                              <SvgButton
                                tip="copy all table"
                                onPress={copyTableToClipboard}
                                editPageState={editPageState}
                              />
                              <SvgButton
                                tip="paste in table"
                                onPress={pasteTableFromClipboard}
                                editPageState={editPageState}
                              />

                            </div>
                          </Col>
                          <Col xs={6} lg={3} className="order-1 order-lg-2">
                            <div className="d-flex gap-3 justify-content-end">
                              {/* <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title="Off Edit Mode"
                              >
                                <div
                                  className="pointer-cursor"
                                  onClick={async (e) => {
                                    await blinkAnimation(e);
                                    toogleEditingMode();
                                  }}
                                >
                                  {editSVG({
                                    width: "2rem",
                                    height: "2rem",
                                  })}
                                </div>
                              </Tooltip>

                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title="Folder"
                                disabled
                              >
                                <div
                                  className="disabled"
                                  onClick={() => {}}
                                  disabled
                                >
                                  {folderSVG({
                                    width: "2rem",
                                    height: "2rem",
                                    opacity: ".3",
                                  })}
                                </div>
                              </Tooltip> */}

                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title="Learn It"
                              >
                                <div
                                  className="pointer-cursor"
                                  onClick={async (e) => {
                                    await blinkAnimation(e);
                                    await handleLearnButton();
                                  }}
                                >
                                  {dotSVG({
                                    width: "1.75rem",
                                    height: "1.75rem",
                                  })}
                                </div>
                              </Tooltip>

                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title="Presentation - Column A to B"
                              >
                                <div
                                  className="pointer-cursor"
                                  onClick={async (e) => {
                                    await blinkAnimation(e);
                                    splitSentences("/slides?type=presentation");
                                  }}
                                >
                                  {persentationSVG({
                                    width: "1.75rem",
                                    height: "1.75rem",
                                  })}
                                </div>
                              </Tooltip>

                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title={
                                  editPageState.subtitle.trim() === ""
                                    ? "Fill column B to learn with flashcards"
                                    : "Study - Column B to A"
                                }
                              >
                                <div
                                  style={{
                                    opacity:
                                      editPageState.subtitle.trim() === ""
                                        ? 0.5
                                        : "1",
                                    cursor:
                                      editPageState.subtitle.trim() === ""
                                        ? ""
                                        : "pointer",
                                  }}
                                  onClick={
                                    editPageState.subtitle.trim() !== ""
                                      ? async (e) => {
                                        await blinkAnimation(e);
                                        splitSentences(
                                          "/slides?type=flashcard"
                                        );
                                      }
                                      : () => { }
                                  }
                                >
                                  <img
                                    src={flashCardIcon}
                                    width={"24px"}
                                    height={"24px"}
                                    alt="Flash Card"
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    className: "bg-dark",
                                    style: { color: `var(--bgColor)` },
                                  },
                                }}
                                arrow
                                title="Start the QUIZ"
                              >
                                <div
                                  className="pointer-cursor"
                                  onClick={async (e) => {
                                    await blinkAnimation(e);
                                    splitSentences("/quiz?type=quiz");
                                  }}
                                >
                                  {quizSVG({
                                    width: "1.55rem",
                                    height: "1.55rem",
                                  })}
                                </div>
                              </Tooltip>
                            </div>
                          </Col>
                        </div>
                      </div>
                    )}
                  </section>

                  {!showSentanceDetailFields && (
                    <div className="text-center">
                      <BlinkAnimatedButton
                        title="learn it"
                        pressed={handleLearnButton}
                      />
                      {/* {id ? (
                        <BlinkAnimatedButton
                          title="learn it"
                          pressed={() => {
                            const stats = {
                              startCount: firestore.FieldValue.increment(1),
                              lastStart: firestore.FieldValue.serverTimestamp(),
                              // lastStartedBy: uid,
                              // startedBy: firestore.FieldValue.arrayUnion(uid),
                              startedAt: firestore.FieldValue.arrayUnion(
                                firestore.FieldValue.serverTimestamp()
                              ),
                            };
                            if (uid) {
                              stats.startedBy =
                                firestore.FieldValue.arrayUnion(uid);
                              stats.lastStartedBy = uid;
                            }

                            splitSentences();
                            firestore()
                              .collection("sets")
                              .doc(id)
                              .update(stats);
                          }}
                        />
                      ) : (
                        <BlinkAnimatedButton
                          title="learn it"
                          pressed={() => {
                            splitSentences();
                          }}
                        />
                      )} */}
                      <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap mt-4 mb-5">
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              className: "bg-dark",
                              style: { color: `var(--bgColor)` },
                            },
                          }}
                          arrow
                          title="Edit your set"
                        >
                          <div
                            className="pointer-cursor d-none d-md-block"
                            onClick={async (e) => {
                              await blinkAnimation(e);
                              toogleEditingMode();
                            }}
                          >
                            {editSVG({
                              width: "2rem",
                              height: "2rem",
                            })}
                          </div>
                        </Tooltip>

                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              className: "bg-dark",
                              style: { color: `var(--bgColor)` },
                            },
                          }}
                          arrow
                          title="Presentation - Column A to B"
                        >
                          <div
                            className="pointer-cursor"
                            onClick={async (e) => {
                              await blinkAnimation(e);
                              splitSentences("/slides?type=presentation");
                            }}
                          >
                            {persentationSVG({
                              width: "2rem",
                              height: "2rem",
                            })}
                          </div>
                        </Tooltip>

                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              className: "bg-dark",
                              style: { color: `var(--bgColor)` },
                            },
                          }}
                          arrow
                          title={
                            editPageState.subtitle.trim() === ""
                              ? "Fill column B to learn with flashcards"
                              : "Study - Column B to A"
                          }
                        >
                          <div
                            style={{
                              opacity:
                                editPageState.subtitle.trim() === ""
                                  ? 0.5
                                  : "1",
                              cursor:
                                editPageState.subtitle.trim() === ""
                                  ? ""
                                  : "pointer",
                            }}
                            onClick={
                              editPageState.subtitle.trim() !== ""
                                ? async (e) => {
                                  await blinkAnimation(e);
                                  splitSentences("/slides?type=flashcard");
                                }
                                : () => { }
                            }
                          >
                            <img
                              src={flashCardIcon}
                              width={"32px"}
                              height={"32px"}
                              alt="Flash Card"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              className: "bg-dark",
                              style: { color: `var(--bgColor)` },
                            },
                          }}
                          arrow
                          title="Start the QUIZ"
                        >
                          <div
                            className="pointer-cursor"
                            onClick={async (e) => {
                              await blinkAnimation(e);
                              splitSentences("/quiz?type=quiz");
                            }}
                          >
                            {quizSVG({
                              width: "1.55rem",
                              height: "1.55rem",
                            })}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {/* <div className="text-center">
                        <button
                          className="btn btn-link text-decoration-none text-uppercase"
                          disabled
                        >
                          Loop: off
                        </button>
                      </div> */}
                  {/* <CreateLink
                        editPageState={editPageState}
                        id={id}
                        setEditPageState={setEditPageState}
                        URLload={URLload}
                        shareSet={shareSet}
                      /> */}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            {/* {!showSentanceDetailFields && (
              <p className="text-center mt-auto position-absolute bottom-0 mb-3 w-100">
                <a
                  href="https://thetime.pl"
                  className="text-dark fst-italic fs-12px"
                  rel="noopener noreferrer"
                  target={"_blank"}
                >
                  by thetime.pl
                </a>
              </p>
            )} */}
          </div>
        </HeaderFooterLayout>
      )}

      {/* <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="subparagraph">Do you want to delete this line?</p>
          <p>{editPageState.sentences[deleteIndexNumber]?.sentence}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleCloseDeleteModal}>
            Close
          </Button>

          <Button
            variant="dark text-white"
            onClick={() => handleDeleteLink(deleteIndexNumber)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </section>
  );
}
