import {
  Button,
  Col,
  Col as MDBCol,
  Container as MDBContainer,
  Row as MDBRow,
  Row,
} from "react-bootstrap";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Speaker from "assets/icons/speaker.svg";
import Microphone from "assets/icons/microphone.svg";
import { CiMaximize1, CiMinimize1 } from "react-icons/ci";
import { Loader } from "@components/loader";
import Fade from "react-reveal/Fade";
import { getFontSize } from "../LearningSession/LearningSession";
import {
  FaCaretLeft,
  FaCaretRight,
} from "react-icons/fa";
import { toast } from "react-hot-toast";
import { IconButton, Tooltip } from "@mui/material";
import {
  circleEmptyIcon,
  closeSVG,
  eyeSVG,
  fullScreenSVG,
  replaySVG,
  translateLanguageSVG,
} from "@components/svgComponents/Svgs";
import { CircleIcon } from "@components/LearnPageComponents/TooltipSection";
import { ProgressBarComponent } from "@components/ProgressBarComponent";
import { blinkAnimation } from "@helpers/index";

const TIME_PER_ANIMATION = 500;

export default function Slides() {
  const [active, setActive] = useState("sentence");
  const [first, setFirst] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showText, setShowText] = useState(true);
  const [showCountSection, setShowCountSection] = useState(false);
  const [partA, setPartA] = useState("text") // text and sound
  const [partB, setPartB] = useState("type") //type and speak 
  const [isShuffledOn, setIsShuffledOn] = useState(false);
  const [recognizedSpeech, setRecognizedSpeech] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [dotIndex, setDotIndex] = useState(0);

  const flashScreen = useRef(null);
  const timeoutRef = useRef(null);
  const skipTimeoutRef = useRef(null);
  const recognitionRef = useRef(null);
  const answerStatusRef = useRef('');
  const [showFlashScreen, setShowFlashScreen] = useState(false);
  const [flashTimer, setFlashTimer] = useState(300);
  const [answerStatus, setAnswerStatus] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);


  const handleSoundSelect = () => {
    setPartA("sound")

  }

  const handleTextSelect = () => {
    setPartA("text")

  }

  const handleTypeSelect = () => {
    setPartB("type")

  }

  const handleSpeakSelect = () => {
    setPartB("speak")

  }
  const speak = async (text) => {
    const lang = 'en-US';

    if ('speechSynthesis' in window) {
      const getVoices = () => {
        return new Promise((resolve) => {
          let voices = window.speechSynthesis.getVoices();
          if (voices.length) {
            resolve(voices);
          } else {
            window.speechSynthesis.onvoiceschanged = () => {
              voices = window.speechSynthesis.getVoices();
              resolve(voices);
            };
          }
        });
      };

      const voices = await getVoices();
      const voice = voices.find(v => v.lang.startsWith(lang));

      if (voice) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = voice;
        utterance.lang = voice.lang;
        window.speechSynthesis.speak(utterance);
      } else {
        console.log(`No voice found for language: English`);
        toast.error(`Unable to speak text in English`);
      }
    } else {
      console.log('Text-to-speech not supported');
      toast.error('Unable to speak text');
    }
  };

  const listenForSpeech = (callback) => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.lang = 'en-US';
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;

      recognitionRef.current.onresult = (event) => {
        const finalResult = event.results[0][0].transcript;
        callback(finalResult);
      };

      recognitionRef.current.start();
    } else {
      console.log('Speech recognition not supported');
      toast.error('Speech recognition not supported');
    }
  };

  const handleSpeechRecognition = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsListening(true);
    setRecognizedSpeech('');
    listenForSpeech((result) => {
      setRecognizedSpeech(result);
      if (compareAnswer()) {
        setAnswerStatus('Correct!');
        stopListening();
      }
    });

    timeoutRef.current = setTimeout(() => {
      if (answerStatusRef.current !== 'Correct!') {
        console.log(answerStatusRef.current, "AnswerStatus");
        setAnswerStatus('Skipped!');
        stopListening();
      }
    }, 10000);
  };

  const stopListening = () => {
    setIsListening(false);
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    return () => {
      stopListening();
    };
  }, []);
  useEffect(() => {
    answerStatusRef.current = answerStatus;
  }, [answerStatus]);


  const handleFlash = () => {
    flashScreen.current && flashScreen.current.classList.add("animate");
    setShowFlashScreen(true);
    setFlashTimer((prev) => prev + 300);
    setTimeout(() => {
      setShowFlashScreen(false);
    }, flashTimer);
  };

  const notActive = useMemo(
    () => (active === "sentence" ? "subtitle" : "sentence"),
    [active]
  );

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "F11") {
        toggleFullScreen();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);
  // redux hooks
  let { sentences, id, isAuthor } = useSelector((state) => state.sentences);


  const slideSentence = useMemo(
    () => [
      ...sentences,
      ...(sentences.some((item) => item.sentence === "The End")
        ? []
        : [
          {
            sentence: "The End",
            subtitle: "The End",
            mastered: null,
            tried: false,
            isEnd: true,
          },
        ]),
    ],
    [sentences]
  );

  const shuffleFun = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const toogleShuffle = (shuffle) => {
    setIsShuffledOn(shuffle);
    if (shuffle) {
      slideSentence.splice(0, slideSentence.length);
      slideSentence.push(...[...shuffleFun([...sentences])], {
        sentence: "The End",
        subtitle: "The End",
        mastered: null,
        tried: false,
        isEnd: true,
      });
    } else {
      slideSentence.splice(0, slideSentence.length);
      slideSentence.push(...sentences, {
        sentence: "The End",
        subtitle: "The End",
        mastered: null,
        tried: false,
        isEnd: true,
      });
    }
    console.log("After shuffle", slideSentence);
  };

  // routers
  const navigate = useNavigate();

  // useStates
  const [isTestStart, setIsTestStart] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // refs
  const fadeScreen = useRef(null);
  const compareAnswer = useCallback(() => {

    const parseSentence = (data) => {
      const punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,.\-.\/:;<=>?@\[\]^_`{|}~]/g;
      const spaceRE = /\s+/g;
      return data
        .trim()
        .replaceAll("'", "'")
        .replaceAll("'", "'")
        .replaceAll("‛", "'")
        .replaceAll("`", "'")
        .replaceAll("“", '"')
        .replaceAll("”", '"')
        .replaceAll("‟", '"')
        .replaceAll("„", '"')
        .toLowerCase()
        .replace(punctRE, "")
        .replace(spaceRE, " ");
    };

    const cleanInput = parseSentence(recognizedSpeech);
    const cleanSubtitle = parseSentence(slideSentence[currentIndex].subtitle);

    return cleanInput.includes(cleanSubtitle);
  }, [recognizedSpeech, slideSentence, currentIndex,]);


  useEffect(() => {
    if (isListening || compareAnswer()) {
      const interval = setInterval(() => {
        setDotIndex((prevIndex) => (prevIndex + 1) % 4);
      }, 250);
      return () => clearInterval(interval);
    } else {
      setDotIndex(0);
    }
  }, [isListening, compareAnswer]);

  useEffect(() => {
    if (recognizedSpeech) {
      console.log('Recognized speech:', recognizedSpeech);
      if (compareAnswer()) {
        setAnswerStatus('Correct!');
      } else {
        setAnswerStatus('');
      }
    }
  }, [recognizedSpeech, compareAnswer]);

  useEffect(() => {
    if (isTestStart && partA === 'sound' && slideSentence[currentIndex]?.sentence !== 'The End') {
      speak(slideSentence[currentIndex]?.sentence)
    }
  }, [currentIndex, isTestStart]);
  const nextSlide = useCallback(() => {
    setFlashTimer(300);
    setRecognizedSpeech('');
    setAnswerStatus('');
    setIsListening(false);
    if (currentIndex < slideSentence.length - 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setIsAnimating(false);
      }, TIME_PER_ANIMATION / 2);
    } else {
      navigate(-1);
    }
  }, [currentIndex, navigate, slideSentence.length]);

  const perviousSlide = useCallback(() => {
    setFlashTimer(300);
    setRecognizedSpeech('');
    setAnswerStatus('');
    if (currentIndex !== 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex(prevIndex => prevIndex - 1);
        setIsAnimating(false);
      }, TIME_PER_ANIMATION / 2);
    }
  }, [currentIndex]);
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "ArrowRight") {
        nextSlide();
      } else if (e.key === "ArrowLeft") {
        perviousSlide();
      } else if (e.key === "Escape") {
        navigate(-1);
      }
    },
    [navigate, nextSlide, perviousSlide]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const startSlide = () => {
    if (slideSentence.length) {
      setIsTestStart(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (slideSentence.length === 0) {
      navigate("/app");
    }
  }, [slideSentence, navigate]);

  const handleMouseEnter = () => {
    setShowCountSection(true);
  };

  const handleMouseLeave = () => {
    setShowCountSection(false);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 32 && (partB === 'speak' || answerStatus)) {
      event.preventDefault();
      if (answerStatus) {
        nextSlide();
      } else {
        if (partB === 'speak' && !slideSentence[currentIndex]?.isEnd && !answerStatus) {
          handleSpeechRecognition();
        }
      }
    }
  };
  useEffect(() => {
    return () => {
      if (skipTimeoutRef.current) {
        clearTimeout(skipTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [answerStatus, handleKeyDown]);
  const handleClick = () => {
    document.querySelector('.partB_input')?.focus();
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      handleClick();
    }, 100);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [showText]);

  return (
    <>
      <div
        className="bodyColor min-vh-100 animate"
        style={{ transition: "all .3s ease-in-out", overflowX: "hidden" }}
        ref={fadeScreen}
      >
        <div className="d-flex justify-content-center align-items-center flex-column min-vh-100">
          {!isTestStart ? (
            <>
              <div className="quiz_start_mian">
                <div className="quiz_start_sec_1">
                  <span className="text_A">
                    A:
                  </span>
                  <button
                    onClick={handleTextSelect}
                    className={`quiz_btn  ${partA === 'text' ? "selected_btn" : ''} `}>
                    TEXT
                  </button>
                  <span className="text_A">
                    /&#160;
                  </span>
                  <button
                    onClick={handleSoundSelect}
                    className={`quiz_btn ${partA === 'sound' ? "selected_btn" : ''} `}>
                    SOUND
                  </button>
                </div>
                <div className="quiz_start_sec_2">
                  <ProgressBarComponent
                    currentLength={0}
                    totalLength={100}
                  />
                </div>
                <div c lassName="quiz_start_sec_3">
                  <div className="quiz_start_sec_1">
                    <span className="text_A">
                      B:
                    </span>
                    <button
                      onClick={handleTypeSelect}
                      className={`quiz_btn  ${partB === 'type' ? "selected_btn" : ''}`}>
                      TYPE
                    </button>
                    <span className="text_A">
                      /
                    </span>
                    <button
                      onClick={handleSpeakSelect}
                      className={`quiz_btn  ${partB === 'speak' ? "selected_btn" : ''}`}>
                      SPEAK
                    </button>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'absolute',
                bottom: '3.5rem',
                left: '50%',
                transform: 'translateX(-50%)',
                margin: 0,
                padding: 0,

              }}>
                <p
                  onClick={startSlide}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'normal',
                    fontSize: '2.8rem',

                  }}
                >
                  Start QUIZ
                </p>
                <p
                  onClick={() => toogleShuffle(!isShuffledOn)}
                  className="slideHeading reverseBTN"
                  style={{ fontSize: "1.3rem", lineHeight: 1 }}
                >
                  shuffle: {isShuffledOn ? "ON" : "OFF"}
                </p>
              </div>
            </>
          ) : (
            <div
              className="w-100"
            >
              {currentIndex !== slideSentence.length - 1 && (
                <section
                  onMouseLeave={handleMouseLeave}
                  onMouseEnter={handleMouseEnter}
                  className="position-fixed bottom-0 start-0 w-100 d-flex flex-column justify-content-center align-items-center gap-2 p-2"
                  style={{
                    opacity: showCountSection ? "1" : ".1",
                    transition: "all .3s ease-in-out",
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center gap-3">

                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Esc to QUIT"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onClick={() => navigate(-1)}
                      >
                        {closeSVG()}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Full Screen"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onClick={toggleFullScreen}
                      >
                        {fullScreenSVG()}
                      </Button>
                    </Tooltip>
                    <div className="d-flex justify-content-center align-items-center g-0">
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Previous"}
                      >
                        <Button
                          className="circular-icons border-0 p-0 rounded-circle bg-transparent"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            perviousSlide();
                            setRecognizedSpeech('');
                            setAnswerStatus('');

                          }}
                          disabled={currentIndex === 0}
                        >
                          <FaCaretLeft size={28} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={!first ? "" : ""}
                      >
                        <IconButton
                          color="primary"
                          className="text-dark p-0"
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await blinkAnimation(e);
                            if (!first) {
                              handleFlash();
                            }

                          }}
                        >
                          {circleEmptyIcon({
                            width: "3rem",
                            height: "3rem",
                          })}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={`${answerStatus ? "Next" : "Skip"}`}
                      >
                        <Button
                          className="circular-icons border-0 p-0 rounded-circle bg-transparent"
                          style={{
                            outline: 'none',
                            boxShadow: 'none',
                            border: 'none',

                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation()
                            if (answerStatus) {
                              nextSlide();
                            } else {
                              setAnswerStatus('Skipped!');
                            }

                          }}
                          disabled={currentIndex === slideSentence.length - 1}
                        >
                          <FaCaretRight size={28} />
                        </Button>
                      </Tooltip>
                    </div>

                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Hide"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onMouseEnter={() => {
                          setShowText(false);
                        }}
                        onMouseLeave={() => {
                          setShowText(true);
                        }}
                      >
                        {eyeSVG({
                          width: "2rem",
                          height: "2rem",
                        })}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Translate in Google"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        as="a"
                        href={`https://translate.google.com?text=${slideSentence[currentIndex]?.[active]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {translateLanguageSVG({
                          width: "2rem",
                          height: "2rem",
                        })}
                      </Button>
                    </Tooltip>
                  </div>
                </section>
              )}
              <div className={`floatingButton d-none`}>
                <Row>
                  <Col>
                    <Button
                      onClick={() => navigate(-1)}
                      className="circular-icons icon-dark border-0"
                    >
                      <IoClose size={24} />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={toggleFullScreen}
                      className="circular-icons icon-dark border-0"
                    >
                      {isFullScreen ? (
                        <CiMinimize1 size={24} onClick={toggleFullScreen} />
                      ) : (
                        <CiMaximize1 size={24} onClick={toggleFullScreen} />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>

              <Fade
                opposite
                duration={TIME_PER_ANIMATION}
                when={!isAnimating}
              >
                <div
                  style={{
                    visibility: !isAnimating ? "visible" : "hidden",
                  }}
                  className="d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                >
                  <div
                    style={{
                      visibility: showText ? "visible" : "hidden",
                      opacity: showText ? 1 : 0,
                      transition: "all .3s ease-in-out",
                    }}
                    onClick={() => { }
                    }
                    className="w-100 d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                      }}
                      className="w-100"
                    >

                      {(partA === 'text' || answerStatus) && !slideSentence[currentIndex]?.isEnd && <h1
                        style={{
                          fontSize: `max(${getFontSize(
                            slideSentence[currentIndex]?.sentence
                          )}rem, 1.5rem)`,
                        }}
                        onClick={() => speak(slideSentence[currentIndex]?.sentence)}
                        className="text-center"
                      >
                        {slideSentence[currentIndex]?.sentence}
                      </h1>}
                      {partA === 'sound' && !answerStatus && !slideSentence[currentIndex]?.isEnd &&
                        <button
                          className="text-center"
                          onClick={() => speak(slideSentence[currentIndex]?.sentence)}
                          style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <CircleIcon
                            size={80}
                            color="inherit"
                            fontSize="inherit"
                          >
                            <span
                              className="text-dark"
                              style={{
                                fontSize: "1rem",
                              }}
                            >
                              <img src={Speaker} alt="Listen" style={{
                                width: '4.075rem',
                                height: '4.075rem',
                                flexShrink: 0
                              }} />
                            </span>
                          </CircleIcon>
                        </button>

                      }
                      {slideSentence[currentIndex]?.isEnd && (
                        <h1
                          style={{
                            fontSize: `max(${getFontSize(
                              slideSentence[currentIndex]?.[active]
                            )}rem, 1.5rem)`,
                          }}
                          className="text-center"
                        >
                          {slideSentence[currentIndex]?.[active]}
                        </h1>
                      )}

                    </div>
                    {!slideSentence[currentIndex]?.isEnd && (
                      <ProgressBarComponent
                        currentLength={answerStatus ? currentIndex + 1 : currentIndex}
                        totalLength={slideSentence.length}
                      />
                    )}
                    {answerStatus && !slideSentence[currentIndex]?.isEnd && (
                      <h1
                        onClick={() => speak(slideSentence[currentIndex]?.subtitle)}
                        style={{
                          fontSize: `max(${getFontSize(
                            slideSentence[currentIndex]?.sentence
                          )}rem, 1.5rem)`,
                          cursor: 'pointer'
                        }}
                        className="text-center"
                      >
                        {slideSentence[currentIndex]?.subtitle}
                      </h1>
                    )
                    }

                    {partB === 'type' && !answerStatus && !slideSentence[currentIndex]?.isEnd && <input
                      autoFocus
                      type="text"
                      className="partB_input"
                      style={{
                        fontSize: `max(${getFontSize(slideSentence[currentIndex]?.sentence)}rem, 1.5rem)`,
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: 0,
                        outline: 'none',
                        textAlign: 'center',
                        width: '80%',
                      }}
                      value={recognizedSpeech}
                      onChange={(e) => {
                        setRecognizedSpeech(e.target.value)
                        if (compareAnswer()) {
                          setAnswerStatus('Correct!');
                        } else {
                          setAnswerStatus('');
                        }
                      }
                      }

                    />
                    }
                    {partB === 'speak' && !slideSentence[currentIndex]?.isEnd && answerStatus === '' && (
                      <div>
                        {!isListening ? (
                          <button
                            onClick={handleSpeechRecognition}
                            style={{
                              border: 'none',
                              outline: 'none',
                              backgroundColor: 'transparent',
                            }}
                          >
                            <CircleIcon size={80} color="inherit" fontSize="inherit">
                              <span
                                className="text-dark"
                                style={{
                                  fontSize: '1rem',
                                }}
                              >
                                <img
                                  src={Microphone}
                                  alt="Listen"
                                  style={{
                                    width: '3.8rem',
                                    height: '3.8rem',
                                    flexShrink: 0,
                                  }}
                                />
                              </span>
                            </CircleIcon>
                          </button>
                        ) : (
                          <div>
                            <div className="dots-container" style={{ display: 'flex', padding: 0 }}
                              onClick={
                                () => {
                                  stopListening();
                                  setAnswerStatus('Skipped!');
                                  setRecognizedSpeech('');

                                }
                              }
                            >
                              <span
                                className={`dot ${dotIndex === 0 ? 'bounce' : ''}`}
                                style={{ fontSize: '9rem', lineHeight: 1 }}
                              >
                                .
                              </span>
                              <span
                                className={`dot ${dotIndex === 1 ? 'bounce' : ''}`}
                                style={{ fontSize: '9rem', lineHeight: 1 }}
                              >
                                .
                              </span>
                              <span
                                className={`dot ${dotIndex === 2 ? 'bounce' : ''}`}
                                style={{ fontSize: '9rem', lineHeight: 1 }}
                              >
                                .
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* {partB === 'speak' && answerStatus && (
                      <p
                        style={{
                          fontSize: `max(${getFontSize(slideSentence[currentIndex]?.sentence)}rem, 1.5rem)`,
                          margin: 0,
                        }}
                      >
                        {recognizedSpeech}
                      </p>
                    )} */}
                    {answerStatus && !slideSentence[currentIndex]?.isEnd && (
                      <div className="ans_status">
                        <p className="ans_text">{answerStatus}</p>
                      </div>
                    )}

                    {!slideSentence[currentIndex]?.isEnd && (
                      <Fade
                        opposite
                        duration={TIME_PER_ANIMATION / 4}
                        when={first || showFlashScreen}
                      >
                        <div
                          style={{
                            visibility:
                              first || showFlashScreen ? "visible" : "hidden",
                          }}
                          className="w-100 d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                        >

                        </div>
                      </Fade>
                    )}
                  </div>

                  {slideSentence[currentIndex]?.isEnd && (
                    <div className="d-flex justify-content-center align-items-center gap-3 mb-5">
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Esc to QUIT"}
                      >
                        <Button
                          className="bg-transparent shadow-none text-dark p-0 border-0"
                          onClick={() => navigate(-1)}
                        >
                          {closeSVG()}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Replay"}
                      >
                        <Button
                          className="bg-transparent shadow-none text-dark p-0 border-0"
                          onClick={() => {
                            setCurrentIndex(0);
                            setFlashTimer(300);
                          }}
                        >
                          {replaySVG()}
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
